$('.count-1').each(function () {
  $(this).prop('Counter', 0).animate({
    Counter: $(this).text()
  }, {
    duration: 2000,
    easing: 'swing',
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
});
$('.count-2').each(function () {
  $(this).prop('Counter', 0).animate({
    Counter: $(this).text()
  }, {
    duration: 1000,
    easing: 'swing',
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
});
$('.count-3').each(function () {
  $(this).prop('Counter', 0).animate({
    Counter: $(this).text()
  }, {
    duration: 3000,
    easing: 'swing',
    step: function (now) {
      $(this).text(Math.ceil(now));
    }
  });
});


// portfolio
$(".gallery ul li a").click(function () {
	var itemID = $(this).attr("href");
	$(".gallery ul").addClass("item_open");
	$(itemID).addClass("item_open");
	return false;
});
$(".close").click(function () {
	$(".port, .gallery ul").removeClass("item_open");
	return false;
});

$(".gallery ul li a").click(function () {
	$("html, body").animate(
		{
			scrollTop: parseInt($("#top").offset().top)
		},
		400
	);
});
